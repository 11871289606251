import { MenuItem, TextField } from "@mui/material";
import React from "react";
import mut from "../hooks/mut";
import { ConstructionOutlined } from "@mui/icons-material";
import Lang from "./Lang";

/**
 * 
 * @param {Array} choices array of choices, either array of string or array of objects with {_id,name}
 * @param {string} label 
 * @param {string} url optional - url to update when value changes
 * @param {string} field optional - field to update when value changes
 * @returns 
 */

export default function SelectFromList(props) {
  const { choices, value, setValue, style, label, size, none, fullWidth, url, field } = props

  const change = (val) => {
    if (url && field) {
      mut("post",url,{[field]:val})
    }
    setValue(val)
  }
  
  if(!choices || choices.length===0) return <div></div>  

  if (typeof choices[0] === "object")return (
    <TextField
      size={size}
      label={label}
      style={style}
      value={value}
      select
      onChange={(e) => change(e.target.value)}
      fullWidth={fullWidth}
      {...props}
    >
      {none ? (
        <MenuItem
          value=""
          key="none"
        >
          <Lang w="none" />
        </MenuItem>
      ) : null}
      {choices.map((choice) => (
        <MenuItem
          value={choice._id}
          key={choice._id}
        >
          {choice.name}
        </MenuItem>
      ))}
    </TextField>
  );
    
    return (
      <TextField
        size={size}
        label={label}
        style={style}
        value={value}
        select
        onChange={(e) => change(e.target.value)}
        fullWidth={fullWidth}
        {...props}
      >
        {none ? (
          <MenuItem value="" key="none">
            None
          </MenuItem>
        ) : null}
        {choices.map((choice,i) => (
          <MenuItem value={choice} key={choice+i}>
            {choice}
          </MenuItem>
        ))}
      </TextField>
    );


    
}