import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { debounce } from "../hooks/debounce";

/**
 * @description Debounced text field
 * @param {string} url optional - post updates to this url
 * @param {string} field optional - update this field
 * @returns 
 */

export default function Text(props) {
const { value, setValue, url, field, delay=1000, fontSize=16 } = props
const [first, setFirst] = useState(false);  // don't fire debounce on first render
      useEffect(() => {
        let db = debounce(url, field, value, first, delay);
        setFirst(true);
        return db;
      }, [value]);

  return (<TextField value={value} onChange={(e) => setValue(e.target.value)} inputProps={{style:{fontSize}}}{...props} />)
}