import React from "react";
import { net } from "../../../database";
import { Button } from "@mui/material";

export default function StopUserButton({ id, result }) {
  const stopUser = (id) => {
    net.post(`/user/${id}`, { status: 1 }).then((res) => {
      result(res);
    });
  };
  return (
    <Button color="error" onClick={() => stopUser(id)}>
      Stop
    </Button>
  );
}
