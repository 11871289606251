import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { Table } from "antd";
import { CenterContext, StaffContext, UserContext } from "../../../../Context";
import VND from "../../components/VND";
import FormatDate from "../../components/FormatDate";
import Staff from "../../components/Staff";
import Location from "../../components/Location";
import MonthChoice from "../../components/MonthChoice";
import { exportFile } from "table-xlsx";
import { Button, TextField } from "@mui/material";
import DeleteButton from "../../components/DeleteButton";
import Department from "../../components/Department";
import SelectFromList from "../../components/SelectFromList";


export default function Costs(props) {

    const [costs, setCosts] = React.useState(undefined)
    const [year, setYear] = React.useState(new Date().getFullYear())
    const [month, setMonth] = React.useState(new Date().getMonth());
    const [department,setDepartment] = React.useState("")
    const { center } = React.useContext(CenterContext)
    const { findStaff } = React.useContext(StaffContext)
    const {user} = React.useContext(UserContext)
    React.useEffect(() => {
       
        net.get(`/costs?year=${year}&month=${month+1}`).then(res => {
            setCosts(
              res
            );
        })
    },[year,month]);
    if (!costs) return <Loading />
  
    const cols = getCols(user)

    const onExportFileClick = () => {
      exportFile({
        columns: getCols(),
        dataSource: costs
          .filter((c) => (department ? c.department === department : true))
          .map((c) => ({
            ...c,
            date: c.date.substring(0, 10),
            department: center.departments.find((d) => d._id === c.department)
              ? center.departments.find((d) => d._id === c.department).name
              : "None",
            paymentMethod: c.paymentMethod === 0 ? "Cash" : "Internet",
            orderedBy: findStaff(c.orderedBy).name,
            location: center.locations.find((l) => l._id === c.location).name,
          })),
        fileName: `costs-${year}-${month + 1}.xlsx`,
      });
    };


    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: 24, marginBottom: 5
          }}
        >
          <div>Costs</div>
         {/*  <SelectFromList choices={center.departments} value={department} setValue={setDepartment} size="small" none label="Department" style={{width: 150}} /> */}
          <MonthChoice
            year={year}
            month={month}
            setYear={setYear}
            setMonth={setMonth}
          />
          {/* <Button onClick={() => onExportFileClick()}>Export to Excel</Button> */}
          <div>
            <VND value={costs.filter(c=>department ? c.department===department : true).reduce((a, b) => a + b.amount, 0)} />
          </div>
        </div>
        <Table
          columns={cols}
          dataSource={costs.filter(c=>department ? c.department===department : true).map((c) => ({
            ...c,
            department: <Department value={c.department} />,
            paymentMethod: c.paymentMethod === 0 ? "Cash" : "Internet",
            amount: <VND value={c.amount} />,
            date: <FormatDate value={c.date} />,
            orderedBy: <Staff id={c.orderedBy} />,
            location: <Location id={c.location} />,
            deleteButton: user.roles.includes("Owner") ? (
              <DeleteButton
                route={`/costs/${c._id}`}
                success={() =>
                  setCosts((prev) => prev.filter((p) => p._id !== c._id))
                }
              />
            ) : null,
          }))}
        />
      </div>
    );
}


function getCols(user) {
    const cols = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Category",
       /*  dataIndex: "category", */
        key: "category",
        renderCell: (props) => (<div>{props}</div> )
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Person",
        dataIndex: "orderedBy",
        key: "orderedBy",
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
      },
      {
        title: "Method",
        dataIndex: "paymentMethod",
        key: "paymentMethod",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
    ];
    if (user && user.roles.includes("Owner")) {
        cols.push({
          title: "Delete",
          dataIndex: "deleteButton",
          key: "deleteButton",
        });
    }
    return cols
}
