import { Chip, TextField } from "@mui/material";
import React from "react";
import mut from "../hooks/mut";


export default function TimeChooser(props) {
    const { value, setValue,times,label,size="normal",url,field } = props
    const [other, setOther] = React.useState(false)

     const change = (val) => {
       if (url && field) {
         mut("post", url, { [field]: val });
       }
       setValue(val);
     };
    
    if (!times) return (
      <TextField
        value={value || ""}
        onChange={(e) => change(e.target.value)}
        type="time"
        label={label}
        size={size}
        InputLabelProps={{ shrink: true }}
      />
    );

    return (<div style={{ border: "1px solid #bbb", borderRadius: 5 }}>
        {label ? <div style={{fontSize:12,padding: 3,paddingLeft:10,color:"#666"}}>{label}</div>: null}
        <div style={{ margin: 5 }}>{times.map(t => <Chip label={t} variant="outlined" style={{margin:3}} onClick={() => { setValue(t);setOther(false) }} {...(t===value && !other && {
        variant:"filled" 
        })} />)} <Chip label="other" variant={other ? "filled" : "outlined"} color="info" onClick={() => setOther(true)} /></div>
        <div style={{margin:5}}>{other ? <TextField value={value || ""} onChange={(e)=>change(e.target.value)} type="time" fullWidth size="small"/>: null}</div>
</div>)
}