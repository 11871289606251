import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import BarChart from "./BarChart";

import LineChart from "./LineChart";
import { addMonths } from "date-fns";
import format from "date-fns/format";
import StatChange from "./common/StatChange";
import { useNavigate } from "react-router-dom";
import Sites from "./Sites";

import { CenterContext } from "../../../../../Context";
import HtTabsSm from "../../../components/HtTabsSm";
export default function Money(props) {
  let { stats, title, stat, color, chartFormat,year,month,link,tab, target,level,sites=false,fieldType=false, upGood=true } = props;
  const [months, setMonths] = React.useState(1);
  const navigate = useNavigate()
    const { center } = useContext(CenterContext);
  /*   let monthdata = getMonthsStats(stats,year,month) */
  if(stats.length<1) return <div>Loading...</div>
  const s = stats
    .filter(
      (st) => st.year && st.year === year && parseInt(st.month) === month+1
    )
   /*  .sort((a, b) =>
      new Date(a.date) < new Date(b.date)
        ? -1
        : new Date(b.date) < new Date(a.date)
        ? 1
        : 0
  ); */
  
  function getDataForLocation(l) {
    let r = [];
    if (l.name === "All locations") {
       for (let st of s) {
     
         r.push(st[stat]);
       }
    } else {
       for (let st of s) {
         let loc = st.locations.find((sl) => sl.location === l._id);
         if (loc && loc[stat]) {
           r.push(loc[stat]);
         }
       }
    }
    
  
     return r;
  }


  const components = center.locations
    .sort((a, b) => (a.name < b.name ? -1 : a.name < b.name ? 1 : 0))
    .map((l) => (
      <LineChart
        labels={s.map((s) => s.day)}
        lineData={getDataForLocation(l)}
        color={color}
        link={link}
        tab={tab}
      />
    ));
 /*  if(months===1 && monthdata.length===0) return <div style={{ height: 310, width:450,display:"flex", justifyContent:"center", alignItems:"center", color:"#ccc"}}>No data</div> */

  return (
    <div style={chartFormat}>
      <div className="dflex-sb">
        <h3 style={{ marginLeft: 5, marginBottom: 0 }}>
          {title}:{" "}
          <NumberFormat
            displayType="text"
            value={s[s.length - 1] && s[s.length - 1][stat] ? s[s.length - 1][stat] : 0}
            thousandSeparator
          />
          {target &&
            ` / ${target} (${Math.round(
              (s[s.length - 1][stat] / target) * 100
            )}%)`}
        </h3>
        {/*   <MonthChoice
          months={months}
          setMonths={setMonths}
        /> */}
      </div>
      {sites ? (
        <Sites
          stats={s}
          field={sites}
          fieldType={fieldType}
        />
      ) : (
        <div style={{ minHeight: 50 }}></div>
      )}
      {year === new Date().getFullYear() && month === new Date().getMonth() ? (
        <StatChange
          stats={stats}
          stat={stat}
          level={level}
          fieldType={fieldType}
          upGood={upGood}
        />
      ) : (
        <div style={{ height: 24 }}></div>
      )}
      <div>
        {months === 1 ? (
          <div
           
          >
            {sites ? (
              <HtTabsSm
                labels={center.locations
                  .sort((a, b) =>
                    a.name < b.name
                      ? -1
                      : a.name < b.name
                      ? 1
                      : 0
                  )
                  .map((l) => l.name)}
                components={components}
              />
            ) : (
              <LineChart
                labels={s.map((s) => s.day)}
                lineData={s.map((s) => s[stat])}
                color={color}
              />
            )}
          </div>
        ) : (
          <BarChart
            labels={getMonthlyData(stats, months, stat).labels}
            barData={getMonthlyData(stats, months, stat).data}
            color={color}
          />
        )}
      </div>
    </div>
  );
}

const getMonthlyData = (stats, months, statName) => {
  let labels = [];
  let data = [];


  let dt = addMonths(new Date(), 1 - months);
  stats.sort((a, b) =>
    new Date(a.date) < new Date(b.date)
      ? 1
      : new Date(b.date) < new Date(a.date)
      ? -1
      : 0
  );

  for (let i = 0; i < months; i++) {
    let stat = stats.find(
      (s) => s.year === dt.getFullYear() && s.month === dt.getMonth()
    );
    labels.push(format(dt, "MMM"));
    data.push(stat[statName]);
  
    dt = addMonths(dt, 1);
    /*  console.log(stat); */
  }
  console.log(labels, data);
  return { labels, data};
};

const getMonthsStats = (stats,year,month) => {
  let monthStats = [];
  let dt = new Date();
  stats.sort((a, b) =>
    new Date(a.createdAt) > new Date(b.createdAt)
      ? 1
      : new Date(b.createdAt) > new Date(a.createdAt)
      ? -1
      : 0
  );
  monthStats = stats.filter(
    (s) =>
      s.year===year &&
      s.month===month+1
  );
  return monthStats;
};
