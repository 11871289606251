import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { net } from "../../../database";


export default function MultiLine(props) {
    const { value, setValue, rows, fontSize, label, url, field, style } = props
    const [first,setFirst] = useState(false)
   
    useEffect(() => {
      if (value !== "" && url && field && first) {
        const getData = setTimeout(() => {
          net.post(url, { [field]: value });
        }, 1000);
       

        return () => clearTimeout(getData);
      }
       setFirst(true);
    }, [value,field,first, url]);

    return (<TextField label={label} rows={rows} value={value} onChange={(e) => setValue(e.target.value)} inputProps={{style:{fontSize,...style}}} fullWidth multiline />)
}