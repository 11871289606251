import { TextField } from "@mui/material";
import React from "react";
import Loading from "../../Loading";
import { net } from "../../../../database";
import HtDate from "../../components/HtDate";
import HtTabs from "../../components/HtTabs";
import useDB from "../../hooks/useDB";
import ByTeacher from "./ByTeacher";

export default function ManageFeedback(props) {


 
  const components = [<ByDate />,<ByTeacher />]
 
  return (
    <div>
     <HtTabs labels={["By Date","By Teacher","By Class"]} components={components} />
    </div>
  );
}

function ByDate(props){


  const [fbDate, setFbDate] = React.useState(new Date());
  const [feedback, setFeedback, loading, error] = useDB(
    `/feedback/${fbDate.toISOString().substring(0, 10)}`
  );

   if (!feedback) return <Loading />;

return (
  <div>
    {" "}
    <div
      className="dflex-sb"
      style={{ alignItems: "center" }}
    >
      <h3>Feedback by date</h3>
      <HtDate
        value={fbDate}
        setValue={setFbDate}
        buttons
        style={{ width: 120 }}
      />
    </div>
    {feedback.map((c) => (
      <DrawClass htClass={c} />
    ))}
  </div>
);
}

function DrawClass({ htClass }) {
  console.log(htClass)
  return (
    <div>
      {htClass.name} {htClass.teacher ? htClass.teacher.name : "Unknown"}
      <hr />
      {htClass.attendance.map((at) => (
        <DrawStudent attendance={at} />
      ))}
      <hr />
    </div>
  );
}

function DrawStudent({ attendance }) {
  return (
    <div>
      <div style={{ fontWeight: "bold" }}>{attendance.student.name}</div>
      {attendance.attendance > 1 ? (
        <div style={{ color: "goldenrod" }}>{attendance.absentReason} </div>
      ) : (
        <div>
          <div>Homework: {attendance.homeWorkNotes}</div>
          <div>Notes: {attendance.notes}</div>
        </div>
      )}
    </div>
  );
}
