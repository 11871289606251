import React, { useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import StaffChooserContext from "../../components/StaffChooserContext";
import HtClass from "../../components/HtClass";
import FormatDate from "../../components/FormatDate";
import SLink from "../../components/SLink";
import Info from "../../components/Info";
import { Cast } from "@mui/icons-material";
import Rating from "../../components/Rating";



export default function ByTeacher(props) {

const [id,setId] = useState("none")
const [feedback, setFeedback, error, loading] = useDB(`/feedback-by-teacher/${id}`)


if (loading) return <Loading />;
if (error) return <Error />;


const {lessons,attendance} = feedback


    return (
      <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Teachers</h3>
          <StaffChooserContext
            filter="Teacher"
            value={id}
            setValue={setId}
            style={{ width: 200 }}
          />
            </div>
            {lessons.map(l => <DrawLesson attendance={attendance.filter(f=>f.lesson===l._id)} lesson={l} />)}
      </div>
    );
}

function DrawLesson(props){


const {lesson,attendance} = props


    return (
      <div>
        <div
          style={{
            background: "#888",
            display: "flex",
            justifyContent: "space-between",
            color: "white",
            padding: 5,
            borderRadius: 5,
            marginBottom: 5,
          }}
        >
          <HtClass
            htClass={lesson.classId}
            link
            style={{ width: 200, color: "white", textDecoration: "none" }}
          />{" "}
          <FormatDate value={lesson.tDate} />
          {attendance && attendance.length && attendance[0].feedbackComplete > 0 && (
            <FormatDate value={attendance[0].feedbackComplete} />
          )}
        </div>
        {attendance.map((a) => (
          <DrawAttendance at={a} />
        ))}
      </div>
    );
}

function DrawAttendance(props){


    const { at } = props
    
    let cats = []
    for (let c in at) {
        cats.push({name: c, value: at[c]})
    }
    let color = "goldenrod"
    if(at.attendance===3) color="red"

    if(at.attendance>1) return (
      <div style={{ color }}>
        <SLink id={at.student} /> {at.absentReason}
        <hr />
      </div>
    );


    return (
      <div>
        <SLink id={at.student} />
        {/*  {cats.map(c=> <Rating value={c.value} />)} */}
        <Info
          title="Homework"
          body={at.homeWorkNotes}
          empty="None"
        />
        <Info
          title="Feedback"
          body={at.notes}
          empty="None"
        />
        <hr />
      </div>
    );
}