import React, { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { net } from "../../../../database";
import StaffChooserContext from "../../components/StaffChooserContext";
import { CenterContext } from "../../../../Context";
import useDB from "../../hooks/useDB";
import Loading from "../../Loading";
import Error from "../../components/Error";
import SelectFromList from "../../components/SelectFromList";

export default function AddShiftDialog(props) {
  const { open, setOpen, showDate, setShifts } = props;
  const {center} = useContext(CenterContext)
  const [newShift, setNewShift] = React.useState({
    startTime: "",
    endTime: "",
    hours: "",
  });
  const [staffId, setStaffId] = React.useState("");

/*   const [staff, setStaff, error, loading] = useDB(`/user/role-salary`)
  if (loading) return "loading"
  if (error) return "error" */
 /*  if (loading) return <Loading />
  if (error) return <Error /> */

// console.log(staff)


  const addShift = () => {
    net
      .post(`/staff/${staffId}/shifts/`, { ...newShift, location: center.locations.filter(l=>l.name!=="All locations")[localStorage.getItem("location")]._id, date: showDate.toISOString().substring(0,10) })
      .then((r) => {
        setShifts(r);
        setOpen(false);
        setNewShift({
          startTime: "",
          endTime: "",
          hours: "",
        });
        setStaffId("");
      });
  };

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle>Add a new shift for a staff member</DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
          sx={{ mb: 1 }}
        >
           <StaffChooserContext
            value={staffId}
            onChange={(e) => setStaffId(e.target.value)}
            fullWidth
            label="Staff member"
            active
          />
         {/*  <SelectFromList
            choices={staff}
            value={staffId}
            onChange={(e) => setStaffId(e)}
          /> */}
          {/*  <LocationChoice
            value={newShift.location}
            onChange={(e) =>
              setNewShift({ ...newShift, location: e.target.value })
            }
            label="Location"
            fullWidth
          /> */}
          <TextField
            type="time"
            value={newShift.startTime}
            label="Start Time"
            InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => {
              let hours = 0;
              try {
                let st = new Date("2022-01-01 " + e.target.value + ":00");
                let et = new Date("2022-01-01 " + newShift.endTime + ":00");
                hours = et.getTime() - st.getTime();
                hours = hours / (1000 * 3600);

                hours = Math.round((hours + Number.EPSILON) * 100) / 100;
              } catch (err) {}
              setNewShift({
                ...newShift,
                startTime: e.target.value,
                hours,
              });
            }}
          />
          <TextField
            type="time"
            value={newShift.endTime}
            label="Finish Time"
            InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => {
              let hours = 0;
              try {
                let st = new Date("2022-01-01 " + newShift.startTime + ":00");
                let et = new Date("2022-01-01 " + e.target.value + ":00");
                hours = et.getTime() - st.getTime();
                hours = hours / (1000 * 3600);

                hours = Math.round((hours + Number.EPSILON) * 100) / 100;
              } catch (err) {}
              setNewShift({
                ...newShift,
                endTime: e.target.value,
                hours,
              });
            }}
          />
          <TextField
            type="number"
            value={newShift.hours}
            label="Hours"
            InputProps={{
              readOnly: true,
            }}
            tabIndex="100"
            fullWidth
            onChange={(e) =>
              setNewShift({ ...newShift, hours: e.target.value })
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setNewShift({
              startTime: "",
              endTime: "",
              hours: "",
            });
            setStaffId("");
            setOpen(false);
          }}
        >
          Close
        </Button>
        <Button
          onClick={addShift}
          {...((!check(newShift) ||
            staffId === "" ||
            (newShift.hours && newShift.hours <= 0)) && {
            disabled: true,
          })}
        >
          Add shift
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function check(obj) {
  let result = true
  for (let o in obj) {

    if(obj[o]==="") result=false
  }
  return result
}