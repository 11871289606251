import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";
import React, { useState } from "react";
import PhoneNumber from "../../components/PhoneNumber";
import Text from "../../components/Text";
import SelectFromList from "../../components/SelectFromList";
import Lang from "../../components/Lang";
import ChooseDate from "../../components/ChooseDate";
import mut from "../../hooks/mut";

export default function NewTestDialog(props) {
    const { open, setOpen,setContacts } = props;
    const [contact, setContact] = useState({
        phone: "",
        name: "",
        notes: "",
        location: "",
        testDate: new Date()
    })

    const add = async () => {
        let result = await mut("post", "/add-marketing-number", contact)
        if (result && result.success) {
            setContacts(prev => [...prev, result.newContact])
            setContact({
              phone: "",
              name: "",
              notes: "",
              location: "",
              testDate: new Date(),
            });
            setOpen(false)
        }
    }

    const choices = [
      {
        _id: "Referred by another parent",
        name: <Lang w="referredByAnotherParent" />,
      },
      {
        _id: "Relation of existing student",
        name: <Lang w="relationOfExistingStudent" />,
      },
      {
        _id: "Student who was a lead or stopped",
        name: <Lang w="studentWhoWasALeadOrStopped" />,
      },
      {
        _id: "Came into the center themselves",
        name: <Lang w="cameIntoTheCenterThemselves" />,
      },
      {
        _id: "Other reason",
        name: <Lang w="otherReason" />,
      },
    ];

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle><Lang w="addANewTest" /></DialogTitle>
      <DialogContent
        style={{ display: "flex", flexDirection: "column", gap: 15, paddingTop:10 }}
      >
        <PhoneNumber
          value={contact.phone}
          setValue={(phone) => {
            setContact((prev) => ({ ...prev, phone }));
          }}
          label="Phone"
        />

        <Text
          value={contact.name}
          setValue={(name) => {
            setContact((prev) => ({ ...prev, name }));
          }}
          label="Name"
        />
        <Text
          value={contact.notes}
          setValue={(notes) => {
            setContact((prev) => ({ ...prev, notes }));
          }}
          label="Notes"
          multiline
          rows={2}
          style={{ fontSize: 14 }}
        />
        <SelectFromList
          choices={choices}
          value={contact.location}
          setValue={(location) => {
            setContact((prev) => ({ ...prev, location }));
          }}
        label={<Lang w="whereAreTheyFrom?" />}
        />
        <ChooseDate
          label="Date of test"
          value={contact.testDate}
          setValue={(testDate) => {
            setContact((prev) => ({ ...prev, testDate }));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
              <Button color="success" onClick={add} {...((contact.name === "" || contact.phone === "" || contact.notes==="" || contact.location==="") && {
       disabled : true 
        })}>Go</Button>
      </DialogActions>
    </Dialog>
  );
}