import React from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VND from "../../../../components/VND";
export default function StatChange(props) {
  //const {l,language} = props;
  const { stats, stat, level, fieldType, upGood=true } = props;
  
  const bonusLevel = 30000000


  let difference =
    parseInt(stats[stats.length - 1][stat]) -
    parseInt(stats[stats.length - 2][stat]) || 0;
  const l = Math.floor((stats[stats.length - 1][stat] - level) / bonusLevel)

  console.log(stat)

  return (
    <div style={{ marginLeft: 5, display: "flex", alignItems: "center" }}>
      <UpDown
        difference={difference}
        upGood={upGood}
      />
      <div>
        {" "}
        {fieldType && fieldType === "money" ? (
          <VND value={Math.abs(difference)} />
        ) : (
          Math.abs(difference)
        )}
        {level && " - Bonus level: " + l + " - To level " + parseInt(l+1) +  ": "}
        {level && (
          <VND
            value={
              level +
              bonusLevel * (l + 1) -
              parseInt(stats[stats.length - 1][stat])
            }
          />
        )}
        {!level && " from yesterday"}
      </div>
    </div>
  );
}


function UpDown(props) {
  const { difference, upGood} = props
  if (upGood === false) {
    if (difference > 0) return <ExpandLessIcon style={{ color: "red" }} />;
    if(difference===0) return <></>
     return <ExpandMoreIcon style={{ color: "green" }} />;
  }

  if (difference < 0) return <ExpandMoreIcon style={{ color: "red" }} />
  if (difference === 0) return <></>;
  return  <ExpandLessIcon style={{ color: "green" }} />
}