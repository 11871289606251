import React from "react";
import { menu } from "./menu";
//import * as Icons from "@material-ui/icons/";
import { useNavigate } from "react-router-dom";
import "./sidebar.css";
import {
  ListSubheader,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Drawer,
  Divider,
  AppBar,
} from "@mui/material";

export default function Sidebar(props) {
  let navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = React.useState("ls0li0");

  const handleMenu = (event, index, href) => {
    setSelectedIndex(index);
    navigate(href);
  };
  // console.log(props);
  return (
    <Drawer
      sx={{
        width: 200,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 200,
          boxSizing: "border-box",
        },
      }}
      PaperProps={{
        sx: {
          background:
            "linear-gradient(49deg, rgba(2,0,36,1) 0%, rgba(0,72,105,1) 0%, rgba(0,157,189,1) 100%)",
          color: "white",
        },
      }}
      className="side-bar-container"
      variant="permanent"
      anchor="left"
    >
      <div className="center-name">HT Explore</div>
      <List className="sidebar" component="nav" aria-label="main menu">
        {menu
          .filter(
            (m) => m.access === undefined || props.user.roles.includes(m.access)
          )
          .map((m, i) => (
            <div key={"ls" + i}>
              <div className="sidebar-titles">{m.title}</div>
              {m.items.map((item, j) => (
                <ListItem key={"li" + j} disablePadding>
                  <ListItemButton
                    onClick={(event) =>
                      handleMenu(event, "ls" + i + "li" + j, item.href)
                    }
                    selected={selectedIndex === "ls" + i + "li" + j}
                    className="sidebar"
                  >
                    <ListItemIcon sx={{ color: "#eee", fontSize: 14 }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "0.8rem",
                        fontFamily: "Inter",
                        fontWeight: 300,
                      }}
                      primary={item.name[props.language]}
                    />
                    {/*   <div className="sidebar">{item.name[props.language]}</div> */}
                  </ListItemButton>
                </ListItem>
              ))}
              <Divider />
            </div>
          ))}
      </List>
    </Drawer>
  );
}
