import React, { useContext } from "react";
import { LanguageContext } from "../../../Context";
import { monthnames } from "../../../enums";

/**
 * 
 * @param {int} value the javascript month 
 * @returns 
 */

export default function Month({value}) {
let { language } = useContext(LanguageContext)


return(<>{monthnames[value][language]}</>)
}