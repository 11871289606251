import HomeIcon from "@mui/icons-material/Home";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SchoolIcon from "@mui/icons-material/School";
//import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
//import FeedbackIcon from "@mui/icons-material/Feedback";
//import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import CommentIcon from "@mui/icons-material/Comment";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CallIcon from "@mui/icons-material/Call";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";

const l = require("../../lang.json");

export const menu = [
  {
    title: "Home",
    items: [
      {
        name: l.home,
        href: "/",
        icon: <HomeIcon />,
      },
      {
        name: l.timetable,
        href: "/timetable",
        icon: <AccessTimeIcon />,
      },
      {
        name: l.attendance,
        href: "/attendance",
        icon: <AssignmentIcon />,
      },
    ],
  },
  /*  {
    title: "Head Teachers",
    access: "Head Teacher",
    items: [
      {
        name: l.makeupClasses,
        href: "manage/makeupclasses/timetable",
        icon: <AssignmentIcon />,
      },
      {
        name: ["Extra classes", "Extra classes"],
        href: "/extra-classes",
        icon: <SchoolIcon />,
      },
      { name: l.feedback, href: "/manage/feedback", icon: <FeedbackIcon /> },
      { name: l.staff, href: "/manage/staff", icon: <PersonIcon /> },
    ],
  }, */
  {
    title: "Admin",
    access: "Admin",
    items: [
      {
        name: l.staff,
        href: "/staffshifts",
        icon: <AssignmentIcon />,
      },
      { name: l.money, href: "/money", icon: <AttachMoneyIcon /> },
      { name: l.owed, href: "/owed/1", icon: <AttachMoneyIcon /> },
      { name: l.absent, href: "/absent", icon: <SchoolIcon /> },
      /*  { name: ["New Absent","New Absent"], href: "/absent2", icon: <SchoolIcon /> }, */

      { name: l.care, href: "/care", icon: <SchoolIcon /> },
      { name: l.admin, href: "/admin", icon: <AssignmentIcon /> },
      {
        name: ["Extra classes", "Extra classes"],
        href: "/extra-classes",
        icon: <SchoolIcon />,
      },
      /*   {
        name: ["Low attendance", "Low attendance"],
        href: "/student-data",
        icon: <SchoolIcon />,
      }, */
      /*   {
        name: ["Tests", "Tests"],
        href: "/tests",
        icon: <SchoolIcon />,
      }, */
      /*  { name: ["Next payment","Next payment"], href: "/sales/next-payment", icon: <AttachMoneyIcon /> } */
      /*  {
        name: l.billing,
        href: "/accounting/billing",
        icon: <AttachMoneyIcon />,
      }, */
      /*  { name: l.students, href: "/students", icon: <SchoolIcon /> }, */
    ],
  },
  /* {
    title: "Teaching",
    items: [
      { name: l.feedback, href: "/feedback", icon: <FeedbackIcon /> },
      {
        name: l.myStudents,
        href: "/mystudents",
        icon: <SchoolIcon />,
      },
    ],
  }, */
  {
    title: "Sales",
    access: "Sales",
    items: [
      {
        name: l.messages,
        href: "/sales/messages",
        icon: <CommentIcon />,
      },
      {
        name: l.marketing,
        href: "/marketing",
        icon: <CallIcon />,
      },
      {
        name: l.paymentFrequency,
        href: "/sales/payment-frequency",
        icon: <CommentIcon />,
      },
      /*  {
        name: l.bills,
        href: "/billcustomers",
        icon: <AttachMoneyIcon />,
      }, */
      {
        name: l.data,
        href: "/sales/students",
        icon: <AssignmentIcon />,
      },
      {
        name: ["Potentials", "Potentials"],
        href: "sales/leads",
        icon: <AssignmentIcon />,
      },
      {
        name: ["Customer care", "Customer care"],
        href: "sales/care",
        icon: <SchoolIcon />,
      },
      { name: l.newLost, href: "manage/newlost", icon: <SchoolIcon /> },

      /*  {
        name: l.makeupClasses,
        href: "manage/makeupclasses/timetable",
        icon: <AssignmentIcon />,
      }, */
      /*  {
        name: l.testing,
        href: "/sales/testing",
        icon: <AssignmentIcon />,
      },
      {
        name: l.feedback,
        href: "sales/feedback",
        icon: <AssignmentIcon />,
      },
*/
      {
        name: l.schedule,
        href: "sales/newclasses",
        icon: <AssignmentIcon />,
      },
      {
        name: l.pending,
        href: "pending",
        icon: <AssignmentIcon />,
      },
      {
        name: ["Classes list", "Classes List"],
        href: "/classlist",
        icon: <SchoolIcon />,
      },
    ],
  },
  {
    title: "Accounting",
    access: "Accountant",
    items: [
      /*  { name: l.accounting, href: "accounting", icon: <AssignmentIcon /> }, */
      { name: l.salaries, href: "/manage/salaries", icon: <PersonIcon /> },
      { name: l.costs, href: "/manage/costs", icon: <AttachMoneyIcon /> },
      /*   {
        name: l.fixBills,
        href: "/fixbills",
        icon: <AttachMoneyIcon />,
      }, */
    ],
  },
  {
    title: "Manage",
    access: "Manager",
    items: [
      { name: l.overview, href: "manage/overview", icon: <HomeIcon /> },
      { name: l.staff, href: "/manage/staff", icon: <PersonIcon /> },
      { name: l.costs, href: "/manage/costs", icon: <AttachMoneyIcon /> },
      { name: l.settings, href: "/manage/settings", icon: <SettingsIcon /> },
      { name: l.feedback, href: "/manage/feedback", icon: <FeedbackIcon /> },
      { name: l.marketing, href: "/manage/marketing", icon: <CallIcon /> },
      { name: l.sales, href: "/manage/sales", icon: <AttachMoneyIcon /> },
      {
        name: l.pickupService,
        href: "/manage/pickup-service",
        icon: <TwoWheelerIcon />,
      },
      {
        name: l.revenueToday,
        href: "/tech/revenue-today",
        icon: <PersonIcon />,
      },
    ],
  },
  {
    title: "Owner",
    access: "Owner",
    items: [
      { name: l.staffList, href: "/owner/stafflist", icon: <PersonIcon /> },
      {
        name: ["Overview", "Overview"],
        href: "/manage/overview2",
        icon: <PersonIcon />,
      },

      /*  { name: l.staffTree, href: "/owner/stafftree", icon: <PersonIcon /> }, */
    ],
  },
  {
    title: "Tech",
    access: "Tech",
    items: [
      {
        name: l.routeRecord,
        href: "/tech/routerecord",
        icon: <PersonIcon />,
      } /* { name: ["stats","stats"], href: "/manage/overview2/addsalarystat", icon: <PersonIcon /> } */,

      /*  { name: l.staffTree, href: "/owner/stafftree", icon: <PersonIcon /> }, */
    ],
  },
];
