import { IconButton, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

/**
 * Description placeholder
 * @date 5/8/2023 - 6:16:20 PM
 *
 * @export
 * @param {*} search
 * @param {*} setSearch
 * @returns {*}
 */
export default function Search(props) {
  const { search, setSearch, type="text" } = props;
  let newProps = { ...props }
  delete newProps.search
  delete newProps.setSearch
  return (
    <TextField
      value={search}
      type={type}
      onChange={(e) => setSearch(e.target.value)}
      placeholder="Search..."
      InputProps={{
        startAdornment: <SearchIcon sx={{ mr: 2 }} />,
        endAdornment: (
          <IconButton
            disabled
            {...(search !== "" && {
              disabled: false,
              color: "error",
            })}
            onClick={() => setSearch("")}
          >
            <CancelIcon />
          </IconButton>
        ),
      }}
      {...newProps}
    />
  );
}
