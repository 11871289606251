import React from "react";
import LineChart from "./LineChart";
import { addMonths, format } from "date-fns";
import MonthChoice from "./common/MonthChoice";
import StatChange from "./common/StatChange";
import { useNavigate } from "react-router-dom";
import Sites from "./Sites";
export default function Students(props) {
  let { stats, chartFormat, title, year, month, field=false } = props;
  const navigate = useNavigate()
  const [months, setMonths] = React.useState(1);
  const filteredStats = months===1 ? stats.filter(s=>s.year===year && s.month===month+1) : stats.filter(
    (s) => new Date(s.date) > addMonths(new Date(), 0 - months)
  )
  stats.sort((a, b) =>
    new Date(a.date) > new Date(b.date)
      ? 1
      : new Date(b.date) > new Date(a.date)
      ? -1
      : 0
  );
  if (filteredStats.length === 0) return <div style={{ height: 310, width:450,display:"flex", justifyContent:"center", alignItems:"center", color:"#ccc"}}>No data</div>
  return (
    <div style={chartFormat}>
      <div className="dflex-sb">
        <h3 style={{ marginLeft: 5, marginBottom: 0 }}>
          {title}: {filteredStats[filteredStats.length - 1].activeStudents}
        </h3>

        {/*   <MonthChoice months={months} setMonths={setMonths} /> */}
      </div>
      {field ? (<Sites
        stats={filteredStats}
        field={field}
      />) : <div style={{ minHeight: 50 }}></div>}
      {year === new Date().getFullYear() && month === new Date().getMonth() ? (
        <StatChange
          stats={stats}
          stat={"activeStudents"}
        />
      ) : (
        <div style={{ height: 24 }}></div>
      )}
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          localStorage.setItem("newLostTabs", "0");
          navigate("/manage/newlost");
        }}
      >
        <LineChart
          labels={setLabels(filteredStats, months)}
          lineData={filteredStats.map((s) => s.activeStudents)}
          color="#02627a"
        />
      </div>
      {/*  <TextStats stats={stats} /> */}
    </div>
  );
}

function setLabels(stats, months) {
  let labels = [];
  switch (months) {
    case 1:
      labels = stats.map((s) => s.day);
      break;
    default:
      labels = stats.map((s) => format(new Date(s.date), "MMM"));
      break;
  }
  return labels;
}

const TextStats = (props) => {
  const { stats } = props;
  return (
    <div>
      <div>
        Active: {stats[stats.length - 1].activeStudents} (
        {stats[stats.length - 1].activeStudents -
          stats[stats.length - 2].activeStudents}
        )
      </div>
      <div>
        New: {stats[stats.length - 1].newStudents} (
        {stats[stats.length - 1].newStudents -
          stats[stats.length - 2].newStudents}
        )
      </div>
      <div>
        Pending: {stats[stats.length - 1].pendingStudents} (
        {stats[stats.length - 1].pendingStudents -
          stats[stats.length - 2].pendingStudents}
        )
      </div>
      <div>
        Stopped: {stats[stats.length - 1].stoppedStudentsThisMonth} (
        {stats[stats.length - 1].stoppedStudentsThisMonth -
          stats[stats.length - 2].stoppedStudentsThisMonth}
        )
      </div>
    </div>
  );
};
