import { differenceInYears, format } from "date-fns";
import React from "react";

export default function DateOfBirth({ dateOfBirth }) {
  if (!dateOfBirth)
    return (
      <div style={{ width: 100 }}>
        <div className="small-text">Date of birth</div>
        <div>Unknown</div>
      </div>
    );
  let dob = new Date(dateOfBirth);
  let d = format(dob, "dd/MM/yyyy");
  let age = parseInt(differenceInYears(new Date(), dob));
  let grade = age - 5;
  if (grade < 1) grade = "Kinder";
  if (grade > 12) grade = "Adult";
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: 100 }}>
        <div className="small-text">Năm sinh</div>
        <div>{d}</div>
      </div>
      <div style={{ width: 50 }}>
        <div className="small-text" style={{ textAlign: "center" }}>
          Tuổi
        </div>
        <div style={{ textAlign: "center" }}>{age}</div>
      </div>
      <div style={{ width: 50 }}>
        <div className="small-text" style={{ textAlign: "center" }}>
          Cấp
        </div>
        <div style={{ textAlign: "center" }}>{grade}</div>
      </div>
    </div>
  );
}
