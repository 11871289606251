import React from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Stack,
  MenuItem,

} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { net } from "../../../../../database";
import Loading from "../../../Loading";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import "./paymentsandcosts.css";
import { format } from "date-fns";
import UndoIcon from "@mui/icons-material/Undo";
import SelectFromList from "../../../components/SelectFromList";
import Lang from "../../../components/Lang";
import VND from "../../../components/VND";

import HtDate from "../../../components/HtDate";

import LocationChoice from "../../../components/LocationChoice";
import StaffAC from "../../../components/StaffAC";

export default function PaymentsCosts(props) {
  const { center, l, language, user } = props;
  const [costs, setCosts] = React.useState([]);
  const [payments, setPayments] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [newCostOpen, setNewCostOpen] = React.useState(false);
  const [openWithdraw, setOpenWithdraw] = React.useState(false);

  const [newCost, setNewCost] = React.useState({
    category:"",
    amount: "",
    paymentMethod: 0,
    date: new Date(),
    description: "",
    location: localStorage.getItem("systemLocation") || "",
    orderedBy: "",
  });
  const [withdrawCash, setWithdrawCash] = React.useState({
    amount: 0,
    float: 0,
    location: localStorage.getItem("systemLocation") || "",
  });

  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [day, setDay] = React.useState(new Date().getDate());

  const [withdrawals, setWithdrawals] = React.useState(undefined);

  React.useEffect(() => {
    net.get(`/paymentsandcosts/${year}/${month}/${day}`).then((r) => {
      setCosts(r.costs);
      setWithdrawals(r.withdrawals);
      setPayments(r.payments);

      setLoading(false);
    });
  }, [year, month, day]);


  if (loading) return <Loading />;

  let totals = [];
  center.paymentTypes.forEach((pt, i) => {
    totals[i] = payments
      .filter((p) => p.type === i)
      .reduce((a, b) => a + b.amount, 0);
  });

  let ctotals = [];
  center.paymentTypes.forEach((pt, i) => {
    ctotals[i] = costs
      .filter((x) => x.paymentMethod === i)
      .reduce((a, b) => a + parseInt(b.amount), 0);
  });

  const handleClose = () => {
    setNewCostOpen(false);
    setNewCost({
      category: 0,
      amount: "",
      paymentMethod: 0,
      date: new Date(),
      description: "",
      location: "",
    });
  };

  const changeWithDrawLocation = (e) => {
    localStorage.setItem("systemLocation", e.target.value);
    setWithdrawCash({ ...withdrawCash, location: e.target.value });
  };

  const addCost = () => {

    newCost.tDate = newCost.date.toISOString().substring(0, 10)
    newCost.year = newCost.date.getFullYear()
    newCost.month = newCost.date.getMonth() + 1
    newCost.day = newCost.date.getDate()
    console.log(newCost)
    net.put("/costs", newCost).then((r) => {
      costs.push(r);
      setCosts(costs);
      setNewCostOpen(false);
      setNewCost({
        category:"",
        amount: "",
        paymentMethod: 0,
        date: new Date(),
        description: "",
        location: "",
      });
    });
  };

  const makeWithdrawal = () => {
    net.post("/cashwithdrawals", withdrawCash).then((result) => {
      setWithdrawals(result);
      setOpenWithdraw(false);
    });
  };

  const undoPayment = (id) => {
    net.get(`/reversepayment/${id}`).then((r) => {
      setPayments(payments.map((p) => p._id !== id));
    });
  };

  return (
    <div>
      <div
        className="dflex-sb"
        style={{
          alignItems: "center",
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <h1>{l.money[language]}</h1>
        <div>
          <div style={{fontSize:12, textAlign:"center", marginBottom:5,color:"gray"}}>Show payments and costs for</div>
          <HtDate
            value={new Date(year, month, day)}
            setValue={(e) => {
              setYear((prev) => new Date(e).getFullYear());
              setMonth((prev) => new Date(e).getMonth());
              setDay((prev) => new Date(e).getDate());
            }}
            buttons
            style={{ width: 120 }}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {center.paymentTypes.map((pt, i) => (
          <Box
            key={"pt" + i}
            border={1}
            borderColor={"#CCCCCC"}
            borderRadius={2}
            padding={1}
            margin={0.5}
            style={{ flexGrow: 1 }}
          >
            {i === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: 30,
                }}
              >
                <div>{pt}</div>
                <Button
                  onClick={() => {
                    setWithdrawCash({
                      ...withdrawCash,
                      amount: parseInt(totals[0]),
                    });
                    setOpenWithdraw(true);
                  }}
                >
                  {l.withdrawal[language]}
                </Button>
              </div>
            ) : (
              <div style={{ height: 30 }}>{pt}</div>
            )}
            <hr />
            <div style={{ fontSize: 11 }}>{l.payments[language]}</div>
            {payments
              .filter((p) => p.type === i)
              .map((payment) => (
                <DrawPayment
                  payment={payment}
                  undoPayment={undoPayment}
                />
              ))}
            <hr />
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>Total {pt} Payments:</div>
              <NumberFormat
                value={parseInt(totals[i])}
                thousandSeparator={true}
                displayType="text"
              />
            </div>
            <hr></hr>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ fontSize: 11 }}>{l.costs[language]}</div>{" "}
              <IconButton
                onClick={() => {
                  setNewCost({ ...newCost, paymentMethod: i });
                  setNewCostOpen(true);
                }}
                variant="contained"
              >
                <AddIcon />
              </IconButton>
            </div>
            {i === 0
              ? costs
                  .filter((x) => x.paymentMethod === 0)
                  .map((cp) => (
                    <div
                      key={cp._id}
                      className="payments-costs-list"
                    >
                      <div>{cp.description} </div>
                      <NumberFormat
                        thousandSeparator={true}
                        value={cp.amount}
                        displayType="text"
                      />
                    </div>
                  ))
              : costs
                  .filter((x) => x.paymentMethod === 1)
                  .map((cp) => (
                    <div
                      key={cp._id}
                      className="payments-costs-list"
                    >
                      <div>{cp.description} </div>
                      <NumberFormat
                        thousandSeparator={true}
                        value={cp.amount}
                        displayType="text"
                      />
                    </div>
                  ))}
            <hr />
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>Total {pt} Costs:</div>
              <NumberFormat
                value={parseInt(ctotals[i])}
                thousandSeparator={true}
                displayType="text"
              />
            </div>
            <hr></hr>
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>{l.balance[language]}</div>
              <NumberFormat
                value={parseInt(totals[i]) - parseInt(ctotals[i])}
                thousandSeparator={true}
                displayType="text"
              />
            </div>
            {i > 0 || withdrawals.length === 0 ? (
              ""
            ) : (
              <DrawWithdrawals
                withdrawals={withdrawals}
                {...props}
              />
            )}
          </Box>
        ))}
        <Dialog
          open={newCostOpen}
          fullWidth
        >
          <DialogTitle style={{display:"flex", justifyContent: "space-between", padding:25}}><div>Add {newCost.method} Cost on </div> <HtDate value={newCost.date} align="right" setValue={(date) => {
                setNewCost(prev => ({ ...prev, date: new Date(Date.UTC(date.getFullYear(),date.getMonth(),date.getDate()))}))
              }
              }  /></DialogTitle>

          <DialogContent>
            <Stack
              spacing={2}
              style={{ marginTop: 5 }}
            >
             
             
              <LocationChoice value={newCost.location} onChange={(e) => setNewCost(prev => ({ ...prev, location: e.target.value }))} style={{ width: 550 }}/>

              <SelectFromList
                choices={center.costCategory}
                value={newCost.category}
                setValue={(category) =>
                  setNewCost({ ...newCost, category })
                }
                label="Cost type"
              />
             <StaffAC value={newCost.orderedBy} setValue={(orderedBy)=>setNewCost(prev=> ({...prev, orderedBy}))} />
              <TextField
                label={l.description[language]}
                value={newCost.description}
                onChange={(e) =>
                  setNewCost({ ...newCost, description: e.target.value })
                }
                multiline
                rows={3}
              />

              <VND
                value={newCost.amount}
                setValue={(amount) => setNewCost({ ...newCost, amount })}
                label={<Lang w="amount" />}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button onClick={addCost} color="success" {...((newCost.location==="" || newCost.department==="" || newCost.description==="" || newCost.amount==="") && {
                    disabled: true,
            })}
            >Add Cost</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openWithdraw}>
          <DialogTitle>Withdraw Cash</DialogTitle>
          <DialogContent>
            <Stack
              spacing={2}
              sx={{ mt: 1 }}
            >
              <NumberFormat
                value={withdrawCash.amount}
                label="Amount"
                customInput={TextField}
                thousandSeparator={true}
                onValueChange={(values) =>
                  setWithdrawCash({ ...withdrawCash, amount: values.value })
                }
              />
              <NumberFormat
                value={withdrawCash.float}
                label="Float"
                customInput={TextField}
                thousandSeparator={true}
                onValueChange={(values) =>
                  setWithdrawCash({ ...withdrawCash, float: values.value })
                }
              />
              <TextField
                select
                label={l.location[language]}
                value={withdrawCash.location}
                onChange={(e) => changeWithDrawLocation(e)}
              >
                {center.locations.map((location, i) => (
                  <MenuItem
                    key={location._id}
                    value={location._id}
                  >
                    {location.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              onClick={() => setOpenWithdraw(false)}
            >
              Close
            </Button>
            <Button
              color="success"
              onClick={makeWithdrawal}
            >
              Withdraw
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

function DrawPayment(props) {
  let { payment, undoPayment } = props;

  let name = payment.students.map((s) => s.name).toString();

  return (
    <div className="dflex-sb" style={{ fontSize: 14 }}>
      {payment.customer ? (
        <Link to={`/customer/${payment.customer._id}`} className="neat">
          {name}
        </Link>
      ) : (
        <div>{name}</div>
      )}
      <div>
        <NumberFormat
          value={payment.amount}
          thousandSeparator
          displayType="text"
        />
        <IconButton onClick={() => undoPayment(payment._id)}>
          <UndoIcon sx={{ fontSize: 11 }} />
        </IconButton>
      </div>
    </div>
  );
}

function DrawWithdrawals(props) {
  let { withdrawals, l, language } = props;
  return (
    <div>
      <hr />
      <div
        style={{
          fontWeight: "bold",
        }}
      >
        {l.withdrawals[language]}:
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: 11,
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: 150 }}>{l.amount[language]}</div>
          <div style={{ width: 150 }}>{l.float[language]}</div>
          <div style={{ width: 150 }}>{l.location[language]}</div>
          <div>{l.cashier[language]}</div>
        </div>
        <div>{l.time[language]}</div>
      </div>
      {withdrawals.map((w) => (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          key={w._id}
        >
          <div style={{ display: "flex" }}>
            <NumberFormat
              value={w.amount}
              thousandSeparator
              displayType="text"
              style={{ width: 150 }}
            />
            <NumberFormat
              value={w.float}
              thousandSeparator
              displayType="text"
              style={{ width: 150 }}
            />
            <div style={{ width: 150 }}>
              {w.location ? w.location.name : "Unknown"}
            </div>
            <div>{w.cashier ? w.cashier.name : "Unknown"}</div>
          </div>
          <div>{format(new Date(w.date), "h:mm aaa")}</div>
        </div>
      ))}
    </div>
  );
}
