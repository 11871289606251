import React, { useContext } from "react";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import SelectFromList from "../../components/SelectFromList";
import { StudentsContext, UserContext } from "../../../../Context";
import { net } from "../../../../database";
import { useNavigate } from "react-router-dom";
import VND from "../../components/VND";
import Loca from "../../components/Loca";
import Info from "../../components/Info";
import Staff from "../../components/Staff";
import Chart from "../../components/Chart";
import ClassTimes from "./ClassTimes";
import { CopyAll } from "@mui/icons-material";
import UpdateLink from "../../components/UpdateLink";

export default function BasicInfo({htClass,setHtClass}) {
  const { user } = useContext(UserContext)
  const {findStudent} = useContext(StudentsContext)
    const navigate = useNavigate()
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>{htClass.name}</h3>
          <div>
            {user.roles.includes("Manager") ||
            user.roles.includes("Head Teacher") ? (
              <Button
                onClick={() => navigate(`/manage/classes/edit/${htClass._id}`)}
              >
                Edit
              </Button>
            ) : (
              ""
            )}
            <Tooltip title="Copy students names">
              <IconButton
                onClick={() => {
                  const text = htClass.students
                    .map((s) => findStudent(s))
                    .reduce((a, b) => a + b.name + "\n", "");
                  navigator.clipboard.writeText(text);
                }}
              >
                <CopyAll />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Info
              title="Teacher"
              body={
                htClass.teacher ? <Staff id={htClass.teacher} /> : "No teacher"
              }
            />
            <Info
              title="Location"
              body={<Loca id={htClass.location} />}
            />
            <Info
              title="Manager"
              body={
                htClass.manager ? <Staff id={htClass.manager} /> : "No manager"
              }
            />
            <ClassTimes htClass={htClass} />
            <div style={{ display: "flex", gap: 20 }}>
              <Info
                title="Students"
                body={htClass.students.length}
              />
              {htClass.pendingStudents.length > 0 ? (
                <Info
                  title="Pending"
                  body={htClass.pendingStudents.length}
                />
              ) : null}
            </div>
          </div>
          <Chart url={`/class-attendance-data/${htClass._id}`} />
          <div>
            <Info
              title="Fee"
              body={
                <VND
                  value={htClass.fee[htClass.fee.length - 1].fee}
                  suffix="đ per lesson"
                />
              }
            />

            <div>
              {/*  <Info
                title="Books"
                body={htClass.books.map((book) => (
                  <div>
                    <a
                      className="neat"
                      href={book.link}
                      target="blank"
                    >
                      {book.name}
                    </a>
                  </div>
                ))}
              /> */}
              <UpdateLink
                href={htClass.syllabusLink}
                name={htClass.syllabusName}
                url={`/classes/${htClass._id}/update`}
                field="syllabusLink"
                nameField="syllabusName"
                newData={(newData) => setHtClass(prev => ({ ...prev, ...newData }))}
                label="Syllabus"
              />
              <SelectFromList
                label="Level"
                choices={["Beginner", "Intermediate", "Advanced"]}
                value={htClass.level || ""}
                setValue={(level) => {
                  net
                    .post(`/classes/${htClass._id}/update`, { level })
                    .then((res) => setHtClass(res));
                  setHtClass((prev) => ({ ...prev, level }));
                }}
                size="small"
                style={{ width: 200, marginTop: 15 }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <TextField
                  label="Unit"
                  value={htClass.currentUnit || ""}
                  type="number"
                  onChange={(e) => {
                    setHtClass((prev) => ({
                      ...prev,
                      currentUnit: e.target.value,
                    }));
                  }}
                  onBlur={() =>
                    net.post(`/classes/${htClass._id}/update`, {
                      currentUnit: htClass.currentUnit,
                    })
                  }
                  size="small"
                  style={{ width: 80, marginTop: 5 }}
                />
                <TextField
                  label="Lesson"
                  value={htClass.currentLesson || ""}
                  type="number"
                  onChange={(e) => {
                    let currentLesson = e.target.value;

                    setHtClass((prev) => ({ ...prev, currentLesson }));
                  }}
                  onBlur={() =>
                    net.post(`/classes/${htClass._id}/update`, {
                      currentLesson: htClass.currentLesson,
                    })
                  }
                  size="small"
                  style={{ width: 80, marginTop: 5 }}
                />
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    );
}